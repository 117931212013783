<template>
  <v-navigation-drawer
      id="core-navigation-drawer"
      v-model="drawer"
      :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
      :expand-on-hover="expandOnHover"
      :right="$vuetify.rtl"
      src="@/assets/background.jpg"
      mobile-breakpoint="960"
      app
      width="260"
      v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
          :gradient="`to bottom, ${barColor}`"
          v-bind="props"
      />
    </template>

    <v-divider class="mb-1"/>

    <v-list
        expand
        nav
        white
    >
      <v-list-item>
        <v-list-item-avatar
            class="align-self-center"
            contain
            min-width="70"
            min-height="70"
        >
          <v-img
              src="@/assets/logo.png"
              min-width="70"
              min-height="70"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
              class="display-2"
              v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2"/>

    <v-list
        nav
        dense
        v-if="isLoggedIn"
    >

      <template v-for="(group, i) in accountMenu">
        <v-list-group
            group="group.group"
            no-action
            :key="i"
            sub-group
            value="true"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ group.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
              v-for="(item, i) in group.links"
              :key="i"
              link
              :to="item.path"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>

      </template>

    </v-list>

    <v-list
        nav
        dense
        v-if="!isLoggedIn"
        active-class="v-list-item--active"
    >
      <template>

        <v-list-item
            v-for="(item, i) in notLoggedInMenu"
            :key="i"
            link
            :to="item.path"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item>

      </template>

    </v-list>


    <template v-slot:append>
      <!--      <base-item-->
      <!--          :item="{-->
      <!--          title: $t('upgrade'),-->
      <!--          icon: 'mdi-package-up',-->
      <!--          to: '/upgrade',-->
      <!--        }"-->
      <!--      />-->
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {mapState, mapMutations, mapGetters} from 'vuex'

export default {
  name: 'CoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.checkLogin()
  },

  computed: {
    ...mapState(['barColor', 'barImage']),
    ...mapGetters({
      isLoggedIn: 'getIsLoggedIn'
    }),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    computedItems() {
      return this.items.map(this.mapItem)
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('avatar'),
      }
    },
  },

  watch: {
    isLoggedIn(newVal, oldVal) {
    }
  },

  methods: {
    ...mapMutations({
      setAuthorization: 'setAuthorization',
      setIsLoggedIn: 'setIsLoggedIn'
    }),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      }
    },
    checkLogin() {
      let pathName = window.location.pathname
      this.setIsLoggedIn()
      if (!this.isLoggedIn && !this.openUrls.includes(pathName)) {
        this.$router.push(`/login`)
      }
    }
  },

  data: () => ({
    openUrls: [
      '/error',
      '/login',
      '/register',
      '/resetpassword'
    ],
    notLoggedInMenu: [
      {
        title: 'Login',
        icon: 'mdi-login',
        path: '/login'
      }
    ],
    accountMenu: [
      {
        title: 'Admin Review',
        group: '/dashboard/*',
        links: [
          {
            title: 'Review Members',
            icon: 'mdi-account-check-outline',
            path: '/dashboard/review/members',
          },
          {
            title: 'Review Dependents',
            icon: 'mdi-account-multiple-check-outline',
            path: '/dashboard/review/dependents',
          },
          {
            title: 'Review Claims',
            icon: 'mdi-text-box-check-outline',
            path: '/dashboard/review/claims',
          },
          {
            title: 'Review Paymens',
            icon: 'mdi-bank-check',
            path: '/dashboard/review/payments',
          }
        ]
      },
      {
        title: 'Members',
        group: '/member*/*',
        links: [
          {
            title: 'View Members',
            icon: 'mdi-account-details-outline',
            path: '/members/view',
          },
          {
            title: 'Add Member',
            icon: 'mdi-account-plus-outline',
            path: '/member/edit',
          }
        ]
      },
      {
        title: 'Claims',
        group: '/claims*/*',
        links: [
          {
            title: 'Submit a Claim',
            icon: 'mdi-lifebuoy',
            path: '/claim',
          },
          {
            title: 'View Claims',
            icon: 'mdi-bank-transfer-in',
            path: '/claims',
          }
        ]
      },
      {
        title: 'Billing',
        group: '/invoices/*',
        links: [
          {
            title: 'Invoice Search',
            icon: 'mdi-text-box-search-outline',
            path: '/invoices/search',
          },
          {
            title: 'Invoice Report',
            icon: 'mdi-list-box-outline',
            path: '/invoices/report',
          },
          {
            title: 'Generate Invoice(s)',
            icon: 'mdi-text-box-plus-outline',
            path: '/invoices/generate',
          }
        ]
      },
      {
        title: 'My Account',
        group: '/account',
        links: [
          {
            title: 'View Account',
            icon: 'mdi-account-details-outline',
            path: '/account/view',
          },
          {
            title: 'Update Account',
            icon: 'mdi-account-edit-outline',
            path: '/account/update',
          },
          {
            title: 'Change Password',
            icon: 'mdi-lock-reset',
            path: '/account/password',
          },
          {
            title: 'Logout',
            icon: 'mdi-logout',
            path: '/logout',
          }
        ]
      },
    ]
  }),
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item__avatar
    margin: 0px

  .display-2
    padding: 0 5px 0 5px

  .v-list-item__title, .v-list-group__items, .v-list-item__icon
    color: white
    padding: 0 5px 0 5px

  .v-list-group__items .v-list-item--active
    background: #4caf50

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin: 10px 5px 0 5px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
